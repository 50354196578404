<template>
  <div>
    <div v-for="(item, index) in internal_data" :key="index">
      <Question
        v-if="item.type === 'question'"
        v-bind:question="item"
      ></Question>
      <QuestionSet
        v-if="item.type === 'questionset'"
        v-bind:questionset="item"
      ></QuestionSet>
    </div>
  </div>
</template>

<script>
import Question from "../component/Question.vue";
import QuestionSet from "../component/QuestionSet.vue";

export default {
  name: "InternalData",
  props: ["internal_data"],
  components: {
    Question,
    QuestionSet
  }
};
</script>

<template>
  <div>
    <b-card no-body>
      <b-card-header>
        Question : {{ question.description }}

        <b-link
          v-if="!visible"
          class="card-header-action btn-minimize float-right"
          v-b-toggle="'collapse-question-' + question.id"
        >
          <i class="icon-arrow-down"></i>
        </b-link>

        <b-link
          v-if="visible"
          href="#"
          class="card-header-action btn-close float-right"
          @click="visible = !visible"
        >
          <i class="icon-arrow-up"></i>
        </b-link>
      </b-card-header>

      <b-collapse :id="'collapse-question-' + question.id" v-model="visible">

        <b-alert class="m-3 mb-0 pb-0" variant="info" :show="question.condition.length > 0">
          <p><b>Shows based on:</b></p>
          <ul>
            <li v-for="(condition, cindex) in question.condition" :key="cindex">{{ condition.description }}</li>
          </ul>
        </b-alert>

        <b-card-body>


          <b-form-group
            label="Label"
            label-for="label-input"
            invalid-feedback="Label is required"
            :state="validateState('label')"
          >
            <b-form-input
              v-if="!is_html"
              id="label-input"
              v-model="model.label"
              :state="validateState('label')"
            ></b-form-input>
            <editor
            v-if="is_html" v-model="model.label" :options="editorOption" :toolbar="toolbarOptions"></editor>
          </b-form-group>

          

          <b-form-group
            v-if="!is_html"
            label="Sub Label"
            label-for="sub-label-input"
            invalid-feedback="Sub Label is required"
            :state="validateState('label')"
          >
            <b-form-input
            v-if="!is_html"
              id="sub-label-input"
              v-model="model.sub_label"
              :state="validateState('sub_label')"
            ></b-form-input>
            
            <editor
            v-if="is_html" v-model="model.sub_label" :options="editorOption" :toolbar="toolbarOptions"></editor>
          </b-form-group>
        
          <!--
          <b-form-group
            v-if="has_error_message"
            label="Error Message"
            label-for="error-message-input"
            invalid-feedback="Error Message is required"
            :state="validateState('error_message')"
          >
            <b-form-input
              id="error-message-input"
              v-model="model.error_message"
              :state="validateState('error_message')"
            ></b-form-input>
          </b-form-group>
          -->

          <p v-if="question.validation.length > 0"><b>Error Message<span v-if="question.validation.length > 1">s</span></b></p>
          <p v-for="(condition, cindex) in question.validation" :key="cindex" v-html="condition.error_message.error_message.message"></p>
        </b-card-body>

        <!--validation: {{ question.validation }}-->

        <b-card-footer class="bg-transparent">
          <b-button
            @click="update"
            variant="outline-success"
            class="float-right mb-3"
            >Submit</b-button
          >
        </b-card-footer>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import axios from "../../../../shared/axios/auth";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import VueQuillEditor from 'vue-quill-editor'

  // require styles
  import 'quill/dist/quill.snow.css'

  var toolbarOptions = [
    ["bold","italic","underline",],
    [{list:"ordered"},{list:"bullet"}],
  ]

export default {
  name: "Question",
  props: ["question"],
  components: {
      editor: VueQuillEditor.quillEditor
  },
  data: () => {
    return {
      is_html: false,
      toolbarOptions: toolbarOptions,
      editorOption: {
        theme: 'snow',
        modules: {
          toolbar: toolbarOptions
        },
      },
      has_error_message: false,
      model: {
        label: null,
        sub_label: null,
        error_message: null
      },
      visible: false
    };
  },
  mixin: [validationMixin],
  validations() {
    return {
      is_html: false,
      model: {
        label: {
          required
        },
        sub_label: {},
        error_message: {}
      }
    };
  },
  methods: {
    show() {},
    validateState(name) {
      if (this.$v.model[name]) {
        const { $dirty, $error } = this.$v.model[name];
        return $dirty ? !$error : null;
      }
    },
    update() {
      var url = "/questions/" + this.question.id + "/";

      var data = {};

      data['label'] = this.model.label;

      if (this.model.sub_label) {
        data['sub_label'] = this.model.sub_label;
      }

      if (this.model.error_message) {
        data["meta"].error_description = this.model.error_message;
      }

      axios
        .patch(url, data)
        .then(response => (this.model = response.data))
        .catch(error => {
          // Unauthorised
          if (error.response.status === 401) {
            this.$store.dispatch("logout");
          }
          console.log("post error");
          console.log(error.response);
        });

    }
  },
  mounted() {
    this.model.label = this.question.label;
    this.model.sub_label = this.question.sub_label;
    if (this.question.field_type === 'HTMLParagraph') {
      this.is_html = true
    }
    if (this.question.meta) {
      if (this.question.meta.error_description) {
        this.has_error_message = true;
        this.model.error_message = this.question.meta.error_description;
      }
    }
  }
};
</script>

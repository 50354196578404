<template>
  <div>
    <b-card no-body>
      <b-card-header>
        Question Set

        <b-link
          v-if="!visible"
          class="card-header-action btn-minimize float-right"
          v-b-toggle="'collapse-question-set-' + questionset.id"
        >
          <i class="icon-arrow-down"></i>
        </b-link>

        <b-link
          v-if="visible"
          href="#"
          class="card-header-action btn-close float-right"
          @click="visible = !visible"
        >
          <i class="icon-arrow-up"></i>
        </b-link>
      </b-card-header>

      <b-collapse
        :id="'collapse-question-set-' + questionset.id"
        v-model="visible"
      >
        <b-card-body>

          <b-alert class="m-3 mb-0 pb-0" variant="info" :show="questionset.condition.length > 0">
            <p><b>Shows based on:</b></p>
            <ul>
              <li v-for="(condition, cindex) in questionset.condition" :key="cindex">{{ condition.description }}</li>
            </ul>
          </b-alert>

          <b-form-group
            label="Label"
            label-for="label-input"
            invalid-feedback="Label is required"
            :state="validateState('label')"
          >
            <b-form-input
              id="label-input"
              v-model="model.label"
              :state="validateState('label')"
            ></b-form-input>
          </b-form-group>

          <InternalData
            v-if="questionset.internal_data"
            v-bind:internal_data="questionset.internal_data"
          ></InternalData>

        </b-card-body>
        <b-card-footer class="bg-transparent">
          <b-button
            @click="update"
            variant="outline-success"
            class="float-right mb-3"
            >Submit</b-button
          >
        </b-card-footer>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import axios from "../../../../shared/axios/auth";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "QuestionSet",
  props: ["questionset"],
  components: {
    InternalData: () => import('./InternalData.vue')
  },
  data: () => {
    return {
      has_error_message: false,
      loaded: false,
      model: {
        label: null,
        sub_label: null,
        error_message: null
      },
      visible: false
    };
  },
  mixin: [validationMixin],
  validations() {
    return {
      model: {
        label: {
          required
        },
        sub_label: {},
        error_message: {}
      }
    };
  },
  methods: {
    validateState(name) {
      if (this.$v.model[name]) {
        const { $dirty, $error } = this.$v.model[name];
        return $dirty ? !$error : null;
      }
    },
    update() {
      var url = "/questions/sets/" + this.questionset.id + "/";

      var data = {};

      data['label'] = this.model.label;

      axios
        .patch(url, data)
        .then(response => (this.model = response.data))
        .catch(error => {
          // Unauthorised
          if (error.response.status === 401) {
            this.$store.dispatch("logout");
          }
          console.log("post error");
          console.log(error.response);
        });

    }
  },
  mounted() {
    this.model.label = this.questionset.label;
  }
};
</script>
